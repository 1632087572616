import { useRef } from 'react';
import { useNotificationContext } from '@/context/notificationContext';
import useFloatingNotes from '@/hooks/useFloatingNotes';
import {
  styled,
  Button,
  IconButton,
  TextField,
  Card,
  CardContent,
  CardHeader,
  CardActions,
  Typography,
  PopperPlacementType,
} from '@formbio/ui';
import {
  CopySimple as CopySimpleIcon,
  Minus as MinusIcon,
} from '@formbio/ui/components/Icons';

interface CardStyles {
  placement: PopperPlacementType;
}
interface PlacementCoords {
  [key: string]: Array<string>;
}

const WIDTH = 320;
const MAX_LENGTH = 5000;

/**
 * This addresses `bottom` and `left` values based on
 * where we need to place the Notepad
 */

const NOTEPAD_PLACEMENT: PlacementCoords = {
  'bottom-start': ['-300px;', 'auto'],
  'left-start': ['-240px;', '-330px'],
  'top-end': ['60px;', '-270px'],
  'top-start': ['60px;', 'auto'], // default
};

const StyledCard = styled(Card)<CardStyles>(({ theme, placement }) => ({
  position: 'absolute',
  padding: theme.spacing(1),
  bottom: NOTEPAD_PLACEMENT[placement][0],
  left: NOTEPAD_PLACEMENT[placement][1],
  width: WIDTH,
  zIndex: theme.zIndex.modal,
  '#notes': {
    cursor: 'move',
  },
  '& .MuiCardHeader-root': {
    paddingBottom: 0,
    '.MuiTypography-root': {
      ...theme.typography.body2,
      fontWeight: 600,
    },
  },
  '& .MuiOutlinedInput-input': {
    ...theme.typography.body2,
  },
  '& .MuiCardActions-root': {
    justifyContent: 'end',
    paddingTop: 0,
  },
}));

export default function Notepad({
  handleClose,
  placement,
}: {
  handleClose?: () => void;
  placement: PopperPlacementType;
}) {
  const notepadRef = useRef<HTMLDivElement>(null);
  const { setSnackbar } = useNotificationContext();
  const { handleNoteChange, noteContent } = useFloatingNotes();

  function handleNoteContentChange(value: string) {
    handleNoteChange(value);
  }

  function onClose() {
    handleClose?.();
  }

  async function handleCopyAll() {
    await navigator.clipboard.writeText(noteContent || '');
    setSnackbar({
      autoHideDuration: 5000,
      variant: 'info',
      children: 'Notes Copied',
    });
  }

  return (
    <>
      <StyledCard
        data-testid='Notepad'
        elevation={10}
        ref={notepadRef}
        placement={placement}
      >
        <CardHeader
          id='notes'
          title={
            <Typography component='label' htmlFor='note-area'>
              Quick Notes
            </Typography>
          }
          action={
            <IconButton onClick={onClose} size='small' aria-label='close'>
              <MinusIcon />
            </IconButton>
          }
        />
        <CardContent>
          <TextField
            id='note-area'
            multiline
            rows={6}
            fullWidth
            placeholder='Type notes here'
            aria-label='note-area'
            value={noteContent}
            onChange={event => handleNoteContentChange(event.target.value)}
            inputProps={{ maxLength: MAX_LENGTH }}
          />
        </CardContent>
        <CardActions>
          <Button startIcon={<CopySimpleIcon />} onClick={handleCopyAll}>
            Copy All
          </Button>
        </CardActions>
      </StyledCard>
    </>
  );
}
