import Notepad from '@/components/dialogs/Notepad';
import {
  FAB_POS_KEY,
  useDragLocalStorage,
  useNoteButton,
  useNotePlacement,
} from '@/hooks/useFloatingNotes';
import { styled, IconButton } from '@formbio/ui';
import { NotePencil as NotePencilIcon } from '@formbio/ui/components/Icons';
import { useRef, useState } from 'react';
import Draggable, { DraggableData } from 'react-draggable';

const NotePadContainer = styled('div')(({ theme }) => ({
  position: 'absolute',
  display: 'flex',
  flexDirection: 'column',
  bottom: theme.spacing(8),
  left: theme.spacing(2),
  zIndex: theme.zIndex.tooltip,
}));

const StyledButton = styled(IconButton)(({ theme }) => {
  const spacing2 = theme.spacing(2);
  const color = theme.palette.info;
  return {
    position: 'relative',
    display: 'flex',
    backgroundColor: color[600],
    ':hover': {
      backgroundColor: color[500],
    },
    '&.Mui-disabled': {
      backgroundColor: color[300],
      border: `1px solid ${color[300]}`,
    },
    color: theme.palette.primary.contrastText,
    borderRadius: theme.spacing(1),
    border: `1px solid ${color[700]}`,
    padding: spacing2,
    '.MuiSvgIcon-root': {
      width: spacing2,
      height: spacing2,
    },
  };
});

export default function NoteButton() {
  // using localStorage to manage the state directly
  const { handleDrag, handleStop, currentPosition } = useDragLocalStorage(
    FAB_POS_KEY,
    100,
    150,
  );

  const noteButtonRef = useRef<HTMLButtonElement>(null);
  const [open, setOpen] = useNoteButton();
  const [disabled, setDisabled] = useState(false);
  const [placement, handlePlacement] = useNotePlacement(noteButtonRef);

  function handleFabClick() {
    handlePlacement();
    setOpen(open => !open);
  }

  // disable the button when it starts moving
  // to prevent a click on mouse up
  function onDrag(data: DraggableData) {
    handleDrag(data.x, data.y);
    setDisabled(!!data.deltaX || !!data.deltaY);
  }
  function onStop(data: DraggableData) {
    handleStop(data.x, data.y);
    setDisabled(false);
  }

  return (
    <>
      <Draggable
        handle='#note-pad-container'
        bounds='body'
        onDrag={(_, data) => onDrag(data)}
        onStop={(_, data) => onStop(data)}
        position={currentPosition}
      >
        <NotePadContainer id='note-pad-container'>
          {open && (
            <Notepad handleClose={handleFabClick} placement={placement} />
          )}
          <StyledButton
            ref={noteButtonRef}
            onClick={handleFabClick}
            disabled={disabled}
            aria-label='notes-button'
          >
            <NotePencilIcon />
          </StyledButton>
        </NotePadContainer>
      </Draggable>
    </>
  );
}
